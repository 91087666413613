import * as React from 'react';

import {
  Colors,
  Breakpoint,
  Lang,

  useDimensions,

  TextBlock,
  Confetti,

  Container,
  LargeContainer,
} from '@bluecurrent/web-component-lib';

export default function Landing(props) {
  const size = useDimensions();
  const { i18n } = Lang.useTranslation();

  const lang = i18n.language;

  const [confetti, setConfetti] = React.useState('Figure');

  const symbols = [
    'Figure',
    'C',
    'Stripes',
  ];

  React.useEffect(() => {
    setConfetti(symbols[Math.floor(Math.random() * symbols.length)]);
  }, []);

  return (
    <LargeContainer>
      <div
        style={{
          height: 'auto',
          width: '100%',
          position: 'relative',
        }}
      >
        <Container
          share={[50, 50]}
          breakpoint={830}
        >
          <div
            style={{
              height: size.width < 830 ? '75vh' : '100%',
              maxHeight: 600,
              minHeight: 500,
              backgroundColor: Colors.GRADIENT,
              width: '100%',
              borderTopLeftRadius: size.width < Breakpoint.xxl ? 0 : 10,
              borderBottomLeftRadius: size.width < Breakpoint.xxl ? 0 : 10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              paddingLeft: size.width < 830 ? '3%' : '8%',
              paddingRight: size.width < 830 ? '3%' : '8%',
            }}
          >
            <TextBlock
              title={{
                content: props.title,
                fontFamily: 'Museo-Sans',
                fontSize: '3.5em',
                fontWeight: 700,
                portableText: false,
              }}
              text={{
                content: props.text[lang],
                portableText: true,
              }}
              buttons={props.buttons}
              align={size.width < 830 ? 'center' : 'left'}
            />
          </div>
          <div
            style={{
              height: size.width < 830 ? 1000 : '70vh',
              maxHeight: size.width < 830 ? 'auto' : 600,
              minHeight: 500,
              backgroundColor: size.width < 830 ? Colors.BLUE : Colors.GRADIENT,
              width: '100%',
              borderTopRightRadius: size.width < Breakpoint.xxl ? 0 : 10,
              borderBottomRightRadius: size.width < Breakpoint.xxl ? 0 : 10,
              paddingLeft: size.width < 830 ? '3%' : '8%',
              paddingRight: size.width < 830 ? '3%' : '8%',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: size.width < 830 ? 'flex-start' : 'flex-end',
              position: 'relative',
            }}
          >
            <Confetti
              type="DotsPath"
              height={900}
              width={900}
              transform="scaleX(-1)"
              position="absolute"
              top={size.width < 400 ? -250 : -120}
              left={150}
              opacity={size.width < 830 ? 0.5 : 1}
            />
            <div
              style={{
                minHeight: 600,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                minWidth: 600,
                position: 'absolute',
              }}
            >
              <div
                style={{
                  height: size.width < 400 ? 410 : 450,
                  width: '100%',
                  backgroundImage: `url("${props.image}?w=600&h=600&auto=format&q=100")`,
                  backgroundPosition: 'top right',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            </div>
            <Confetti
              type={confetti}
              height={125}
              width={125}
              color={size.width < 830 ? Colors.YELLOW : Colors.GREEN}
              position="absolute"
              right="45%"
              bottom={30}
            />
          </div>
        </Container>
      </div>
    </LargeContainer>
  );
}
