import * as React from 'react';

import {
  Colors,
  Lang,

  scrollToId,

  Text,
} from '@bluecurrent/web-component-lib';

export default function NavItem(props) {
  const { i18n } = Lang.useTranslation();

  const lang = i18n.language;

  const [hover, setHover] = React.useState(false);

  /* eslint-disable-next-line no-underscore-dangle */
  return props.data?.layout?.find((e) => e._type === 'text_block')?.title !== null && (
    <button
      type="button"
      className="Hover"
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={() => {
        scrollToId({
          id: props.data?.id,
          duration: 1500,
        });
      }}
      style={{
        marginLeft: 15,
        marginRight: 15,
        height: '100%',
        fontSize: '1em',
        backgroundColor: 'transparent',
        border: 'solid 0px transparent',
      }}
    >
      <Text
        fontSize="1.1em"
        fontWeight={300}
        color={hover ? Colors.GREY : Colors.DARK_WHITE}
      >
        {
          props.data?.title == null
            /* eslint-disable no-underscore-dangle */
            ? props.data.layout.find((e) => e._type === 'text_block') !== undefined
              && props.data.layout.find((e) => e._type === 'text_block').title !== null
              ? props.data.layout.find((e) => e._type === 'text_block').title[lang]
              : ''
            : props.data?.title[lang]
            /* eslint-enable no-underscore-dangle */
          }
      </Text>
    </button>
  );
}
