import * as React from 'react';

import {
  useDimensions,
} from '@bluecurrent/web-component-lib';

import itemResponsiveHandler from './itemResponsiveHandler';

export default function itemWidthHandler(flexWidth) {
  const size = useDimensions();

  const width = (flexWidth - (15 * (itemResponsiveHandler(size) - 1))) / itemResponsiveHandler(size);

  return width;
}
