const Id = {
  // Phrases
  Phrase_We_Are_Happy_To_Help: '32a40965-564e-4c07-b9df-83ca48261eb7',
  Phrase_Still_Having_Problems: '45df19bf-0722-43ad-adbf-66af7b07b6ef',
  // Company Data
  Company_Data_Email: 'b183d951-7d91-4f5f-a1ef-580264d8bf1e',
  Company_Data_Phone: 'b298bf32-7da3-405d-b91f-3e075e092340',
};

export default Id;
