import * as React from 'react';

import {
  Lang,

  filterBasedOnId,

  Loader,
  Text,
  ContactCard,

  ContentContainer,
  MainContainer,
} from '@bluecurrent/web-component-lib';

import Id from '../../lib/variables';
import {
  productQuery,
  productSlugsQuery,
  contentQuery,
} from '../../lib/queries';
import {
  sanityClient,
  getClient,
  overlayDrafts,
} from '../../lib/sanity.server';

import Landing from '../../components/Content/Product/Landing';
import PageNavigation from '../../components/Sections/Header/PageNavigation';

import ContainerHandler from '../../components/Modules/ContainerHandler';
import ComponentHandler from '../../components/Modules/ComponentHandler';

export default function Product({ data = {} }) {
  const { i18n } = Lang.useTranslation();

  const lang = i18n.language;

  const [loading, setLoading] = React.useState(true);
  const [content, setContent] = React.useState({});

  function isEmpty(obj) {
    if (Array.isArray(obj)) {
      return obj.length === 0;
    }
    return Object.keys(obj).length === 0;
  }

  React.useEffect(() => {
    if (isEmpty(data) !== true) {
      setLoading(false);

      const [
        phraseWeAreHappyToHelp,
        phraseStillHavingProblems,
        companyDataEmail,
        companyDataPhone,
      ] = filterBasedOnId(
        data.content,
        Id.Phrase_We_Are_Happy_To_Help,
        Id.Phrase_Still_Having_Problems,
        Id.Company_Data_Email,
        Id.Company_Data_Phone,
      );

      setContent({
        phraseWeAreHappyToHelp,
        phraseStillHavingProblems,
        companyDataEmail,
        companyDataPhone,
      });
    }
  }, [data]);

  return (
    <>
      <PageNavigation
        items={
          isEmpty(data)
            ? []
            : (
              data?.product?.layout
                ? data.product.layout.filter((e) => e.navigation)
                : []
            )
        }
      />
      <div>
        {
          loading ? (
            <div
              style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Loader />
            </div>
          ) : (
            <>
              <div
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              >
                <Landing
                  title={data.product?.title}
                  text={data.product?.overview}
                  image={data.product?.image}
                  buttons={[
                    {
                      id: 0,
                      /* eslint-disable-next-line no-underscore-dangle */
                      customId: data.product?.installation_manual_details._key,
                      text: data.product?.installation_manual_details?.call_to_action?.[lang],
                      size: 'medium',
                      onClick: () => {
                        window.open(data.product?.installation_manual, '_blank').focus();
                      },
                      colorScheme: 'blue',
                    },
                  ]}
                />
              </div>
              {
                isEmpty(data.product?.layout) !== true
                && data.product?.layout ? data.product?.layout.map((event, index) => (
                  <div
                    /* eslint-disable-next-line no-underscore-dangle */
                    key={event._key}
                    id={event.id !== null ? event.id : ''}
                    style={{
                      paddingTop: index === 0
                        ? 100
                        : event.seperate
                          ? 150
                          : 0,
                    }}
                  >
                    {
                      event.title && (
                        <ContentContainer>
                          <Text
                            textAlign={event.align ?? 'left'}
                            fontSize="2.5em"
                            fontWeight={700}
                          >
                            {event.title[lang]}
                          </Text>
                        </ContentContainer>
                      )
                    }
                    {
                      event.text && (
                        <ContentContainer>
                          <div
                            style={{
                              marginTop: 50,
                            }}
                          >
                            <Text
                              textAlign={event.align ?? 'left'}
                              portableText
                            >
                              {
                                event.text[lang]
                              }
                            </Text>
                          </div>
                        </ContentContainer>
                      )
                    }
                    <div
                      style={{
                        marginTop: 50,
                      }}
                    >
                      <ContainerHandler
                        type={event.container}
                        align={event.align}
                        background={event.background}
                      >
                        {
                          event.layout.map((item, i) => (
                            <React.Fragment
                              /* eslint-disable-next-line no-underscore-dangle */
                              key={item._key}
                            >
                              <ComponentHandler
                                /* eslint-disable-next-line no-underscore-dangle */
                                type={item._type}
                                data={item}
                                index={i}
                                align={event.align}
                              />
                            </React.Fragment>
                          ))
                        }
                      </ContainerHandler>
                    </div>
                  </div>
                  )) : null
              }
              <section
                style={{
                  marginTop: 70,
                  position: 'relative',
                  zIndex: 2,
                }}
              >
                <MainContainer>
                  <ContactCard
                    src="https://cdn.sanity.io/images/pnajo2cq/production/e1adc87d7d5462fce80bcaf9973e5e35007199a8-1100x1000.jpg?w=500&h=500&fit=fill&q=100&crop=center&auto=format"
                    text={{
                      topLeft: {
                        text: content.phraseStillHavingProblems.text[lang],
                        href: false,
                        portableText: true,
                      },
                      bottomLeft: {
                        text: content.phraseWeAreHappyToHelp.text[lang],
                        href: false,
                        portableText: true,
                      },
                      topRight: {
                        text: content.companyDataEmail.text[lang],
                        href: 'mailto:samen@bluecurrent.nl',
                        portableText: true,
                      },
                      bottomRight: {
                        text: content.companyDataPhone.text[lang],
                        href: 'tel:+310850466050',
                        portableText: true,
                      },
                    }}
                  />
                </MainContainer>
              </section>
            </>
          )
        }
      </div>
    </>
  );
}

export async function getStaticProps({ params, preview = false }) {
  const content = overlayDrafts(await getClient(preview).fetch(contentQuery));
  const product = await getClient(preview).fetch(productQuery, {
    slug: params.product,
  });

  return {
    props: {
      preview,
      data: {
        product,
        content,
      },
    },
    revalidate: 300,
  };
}

export async function getStaticPaths() {
  const paths = await sanityClient.fetch(productSlugsQuery);
  return {
    paths: paths.map((slug) => ({ params: { slug } })),
    fallback: true,
  };
}
