import * as React from 'react';

import {
  Lang,
  Colors,

  Text,
  Icon,
} from '@bluecurrent/web-component-lib';

export default function Link(props) {
  const { i18n } = Lang.useTranslation();

  const lang = i18n.language;

  const [hover, setHover] = React.useState(false);

  return (
    <a
      href={props.data.file}
      target="_blank"
      className="Hover"
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: 'transparent',
        border: 'solid 0px transparent',
        fontSize: '1em',
        height: 35,
      }}
      rel="noreferrer"
    >
      <div
        style={{
          height: 35,
          width: 35,
          backgroundColor: 'transparent',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 5,
        }}
      >
        <Icon
          name="Download"
          height={25}
          width={25}
          color={hover ? Colors.BLUE : Colors.GREY}
          iconSet="Feather"
        />
      </div>
      <Text
        textDecoration="underline"
        color={hover ? Colors.BLUE : Colors.GREY}
      >
        {
          props.data.title[lang]
        }
      </Text>
    </a>
  );
}
