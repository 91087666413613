import * as React from 'react';

import {
  Lang,
  Breakpoint,

  useDimensions,

  Text,
  TextBlock,
} from '@bluecurrent/web-component-lib';

import LinkItem from './Link';

export default function TextBlockWrap(props) {
  const size = useDimensions();
  const { _, i18n } = Lang.useTranslation();

  const lang = i18n.language;

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginTop: size.width < Breakpoint.sm ? 15 : 0,
      }}
    >
      <TextBlock
        title={{
          content: ('title' in props.data && props.data.title !== null) ? props.data.title[lang] : '',
          portableText: false,
        }}
        text={{
          content: ('text' in props.data && props.data.text !== null) ? props.data.text[lang] : '',
          portableText: 'text' in props.data,
        }}
        buttons={props.data.buttons && props.data.buttons.map((button) => ({
          /* eslint-disable-next-line no-underscore-dangle */
          id: button._key,
          /* eslint-disable-next-line no-underscore-dangle */
          customId: button._key,
          text: button.title[lang],
          size: 'medium',
          onClick: () => {
            window.open(button.file, '_blank').focus();
          },
          colorScheme: 'blue',
        }))}
      />
      {
        props.data.links && 'links' in props.data && (
          <div>
            <Text
              fontWeight={500}
            >
              {
                _('usefullLinks', { ns: 'ui' })
              }
            </Text>
            {
              props.data.links && (
                <div
                  style={{
                    marginTop: 15,
                  }}
                >
                  {
                    props.data.links.map((link) => (
                      <LinkItem
                        /* eslint-disable-next-line no-underscore-dangle */
                        key={link._key}
                        data={link}
                      />
                    ))
                  }
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
}
