import * as React from 'react';

import {
  Colors,

  Text,
} from '@bluecurrent/web-component-lib';

export default function Item(props) {
  return (
    <div
      style={{
        width: Number.isNaN(props.width) ? 'initial' : props.width,
        marginRight: props.style.marginRight,
        marginBottom: 20,
      }}
    >
      <div
        style={{
          width: Number.isNaN(props.width) ? 'initial' : props.width,
          height: Number.isNaN(props.width) ? 'initial' : props.width,
          backgroundColor: Colors.MEDIUM_WHITE,
          marginBottom: 10,
          borderRadius: 10,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'flex-start',
          paddingLeft: 20,
          paddingBottom: 10,
        }}
      >
        <Text
          color={Colors.GREY}
          fontSize="4.5em"
          fontWeight={700}
        >
          {props.index + 1}
        </Text>
      </div>
      <Text>
        {props.text}
      </Text>
    </div>
  );
}
