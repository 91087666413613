import * as React from 'react';

import {
  Lang,
  Colors,
  Breakpoint,

  useDimensions,

  Button,
  Image,
} from '@bluecurrent/web-component-lib';

import itemWidthHandler from '../../services/itemWidthHandler';

import Video from '../Sections/Video';
import InstructionSequenceItem from '../Sections/InstructionSequence/Item';
import TextBlock from '../Sections/TextBlock';
import LinkItem from '../Sections/TextBlock/Link';

export default function ComponentHandler(props) {
  const size = useDimensions();
  const { i18n } = Lang.useTranslation();

  const lang = i18n.language;

  const [confetti, setConfetti] = React.useState('');
  const [confettiColor, setConfettiColor] = React.useState(Colors.BLUE);
  const [confettiPosition, setConfettiPosition] = React.useState('TopRight');

  const symbols = [
    'Figure',
    'C',
    'Stripes',
  ];

  const colors = [
    Colors.GREEN,
    Colors.PURPLE,
    Colors.YELLOW,
    Colors.BLUE,
    Colors.ORANGE,
  ];

  const position = [
    'TopLeft',
    'BottomLeft',
    'TopRight',
    'BottomRight',
  ];

  React.useEffect(() => {
    setConfetti(symbols[Math.floor(Math.random() * symbols.length)]);
    setConfettiColor(colors[Math.floor(Math.random() * colors.length)]);
    setConfettiPosition(position[Math.floor(Math.random() * position.length)]);
  }, []);

  const selectComponent = () => {
    switch (props.type) {
      case 'video':
        return (
          <div
            style={{
              width: '100%',
            }}
          >
            <Video
              data={props.data}
            />
          </div>
        );
      case 'instruction_sequence':
        return (
          <InstructionSequenceItem
            text={props.data[lang]}
            index={props.index}
            width={itemWidthHandler(props.flexWidth)}
            style={props.style}
          />
        );
      case 'button':
        return (
          <div
            style={{
              width: 'auto',
              marginTop: 25,
              marginBottom: 25,
            }}
          >
            <Button
              text={props.data.title[lang]}
              onClick={() => window.open(props.data.file, '_blank').focus()}
              colorScheme="blue"
            />
          </div>
        );
      case 'links':
        return (
          <div
            style={{
              marginRight: props.align !== 'center' && (size.width >= Breakpoint.sm && props.index === 0) ? 50 : 0,
              marginLeft: props.align !== 'center' && (size.width >= Breakpoint.sm && props.index > 0) ? 50 : 0,
            }}
          >
            {
              props.data.links && (
                <div
                  style={{
                    marginTop: -50,
                    width: '100%',
                  }}
                >
                  {
                    props.data.links.map((link) => (
                      <LinkItem
                        /* eslint-disable-next-line no-underscore-dangle */
                        key={link._key}
                        data={link}
                      />
                    ))
                  }
                </div>
              )
            }
          </div>
        );
      case 'text_block':
        return (
          <div
            style={{
              marginRight: (size.width >= Breakpoint.sm && props.index === 0) ? 50 : 0,
              marginLeft: (size.width >= Breakpoint.sm && props.index > 0) ? 50 : 0,
            }}
          >
            <TextBlock
              data={props.data}
            />
          </div>
        );
      case 'images':
        return (
          <div
            style={{
              height: '100%',
              width: '100%',
              minHeight: 450,
            }}
          >
            <Image
              /* eslint-disable-next-line no-underscore-dangle */
              id={props.data._key}
              src={props.data.src}
              wrapper={props.data.shape}
              confetti={{
                type: props.data.shape !== 'Normal' ? confetti : '',
                height: 150,
                width: 150,
                color: confettiColor,
                position: 'absolute',
                left: confettiPosition === 'TopLeft' || confettiPosition === 'BottomLeft' ? 30 : 'initial',
                bottom: confettiPosition === 'BottomLeft' || confettiPosition === 'BottomRight' ? 150 : 'initial',
                right: confettiPosition === 'TopRight' || confettiPosition === 'BottomRight' ? 30 : 'initial',
                top: confettiPosition === 'TopLeft' || confettiPosition === 'TopRight' ? 150 : 'initial',
              }}
            />
          </div>
        );
      default: return <div />;
    }
  };

  return selectComponent();
}
