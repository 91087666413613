import * as React from 'react';

import {
  Breakpoint,

  useDimensions,

  LargeContainer,
  ContentContainer,
  Container,
} from '@bluecurrent/web-component-lib';

import itemResponsiveHandler from '../../services/itemResponsiveHandler';

export default function ContainerHandler(props) {
  const size = useDimensions();

  /*
   * Get the flex container width & create rows
   */
  const contentContainerRef = React.useRef(0);

  const [rows, setRows] = React.useState([]);
  const [contentWidth, setContentWidth] = React.useState('100%');

  const getWidth = () => {
    if (contentContainerRef.current.clientWidth !== null) {
      setContentWidth(contentContainerRef.current.clientWidth);
    }
  };

  React.useEffect(() => {
    getWidth();
  }, [contentWidth]);

  React.useEffect(() => {
    window.addEventListener('resize', getWidth);

    return () => {
      window.removeEventListener('resize', getWidth);
    };
  }, []);

  React.useEffect(() => {
    const e = [];

    for (let i = 0; i < props.children.length; i += itemResponsiveHandler(size)) {
      e.push(props.children.slice(i, i + itemResponsiveHandler(size)));
    }

    setRows(e);
  }, [size, props.children]);

  /*
   * Pass the flex container width to children elements.
   */
  function cloneThroughFragments(children, columns) {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        if (child.type === React.Fragment) {
          return cloneThroughFragments(child.props.children, columns);
        }
        return React.cloneElement(child, {
          flexWidth: contentWidth,
          style: {
            marginRight: columns.length < itemResponsiveHandler(size) ? 15 : 'initial',
          },
        });
      }
      return child;
    });
  }

  const selectContainer = () => {
    switch (props.type) {
      case 'fullWidthContainer':
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: props.align ?? 'left',
              width: contentWidth,
            }}
          >
            {props.children}
          </div>
        );
      case 'flexContainer':
        return (
          <div
            style={{
              width: contentWidth,
            }}
          >
            {
              rows.map((columns, index) => (
                <div
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={columns[0].key + index}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: rows[index].length < itemResponsiveHandler(size) ? 'flex-start' : 'space-between',
                  }}
                >
                  {cloneThroughFragments(rows[index], columns)}
                </div>
              ))
            }
          </div>
        );
      case 'doubleContainer':
        return (
          <div
            style={{
              width: contentWidth,
            }}
          >
            <Container
              type="row"
              share={[50, 50]}
              breakpoint="sm"
              invert={props.children[0]?.props.children.props.type !== 'image' && size.width < Breakpoint.sm}
            >
              {props.children}
            </Container>
          </div>
        );
      default:
        return (
          <div
            style={{
              width: contentWidth,
            }}
          >
            {props.children}
          </div>
        );
    }
  };

  return (
    <>
      <LargeContainer>
        <div
          style={{
            width: '100%',
            borderTopLeftRadius: size.width < Breakpoint.xxl ? 0 : 10,
            borderTopRightRadius: size.width < Breakpoint.xxl ? 0 : 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: props.background === 'blue-gradient' ? 'linear-gradient(180deg, rgba(229,244,251,1) 0%, rgba(229,244,251,0) 100%)' : 'transparent',
            paddingTop: props.background ? 50 : 0,
            paddingBottom: props.background ? 50 : 0,
          }}
        >
          {selectContainer()}
        </div>
      </LargeContainer>
      <ContentContainer>
        <div
          ref={contentContainerRef}
          style={{
            width: '100%',
            height: 0,
          }}
        />
      </ContentContainer>
    </>
  );
}
