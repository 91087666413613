import * as React from 'react';
import YouTube from 'react-youtube';

export default function YouTubeVideo(props) {
  const options = {
    height: 700,
    width: '100%',
    playerVars: {
      autoplay: 1,
    },
  };

  const onReady = (event) => {
    event.target.playVideo();
    event.target.setPlaybackQuality('hd720');
  };

  const onStateChange = (event) => {
    event.target.setPlaybackQuality('hd720');
  };

  return (
    <YouTube
      videoId={props.id}
      opts={options}
      onReady={onReady}
      onStateChange={onStateChange}
    />
  );
}
