import * as React from 'react';

import {
  Colors,
  Breakpoint,
  Lang,

  useDimensions,

  Confetti,
  Icon,
} from '@bluecurrent/web-component-lib';

import Overlay from './Overlay';

import YouTube from './YouTube';

export default function Video(props) {
  const size = useDimensions();
  const { _ } = Lang.useTranslation();

  const [visible, setVisible] = React.useState(false);

  const setType = () => {
    switch (props.data.video_type) {
      case 'youtube':
        return (
          <YouTube
            id={props.data.id}
          />
        );
      default:
        return (
          <div />
        );
    }
  };

  return (
    <>
      <div
        style={{
          height: 'auto',
          width: '100%',
          borderTopLeftRadius: size.width < Breakpoint.xxl ? 0 : 10,
          borderTopRightRadius: size.width < Breakpoint.xxl ? 0 : 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <section
          style={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <button
            className="Hover"
            id="Button_VideoBackground"
            type="button"
            onClick={() => {
              setVisible(!visible);
            }}
            aria-label={`${_('watchVideo', { ns: 'ui' })} ${_('accessibility.button', { ns: 'ui' }).toLowerCase()}`}
            style={{
              width: '100%',
              height: 650,
              backgroundColor: Colors.MEDIUM_WHITE,
              borderRadius: 15,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              border: 'solid 0px transparent',
              backgroundImage: `url('${props.data.image}')`,
              backgroundSize: 'cover',
              backgroundRepeat: 'none',
              backgroundPosition: 'center',
              position: 'relative',
              zIndex: 2,
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                opacity: 0.5,
              }}
            >
              <Confetti
                type="DotsPath"
                height={1000}
                width={1000}
                color={Colors.WHITE}
                transform="scaleX(-1)"
                position="absolute"
                right={-200}
                top={50}
              />
            </div>
            <Icon
              name="Play"
              height={45}
              width={45}
              color={Colors.GREY}
              iconSet="FA"
            />
          </button>
        </section>
      </div>
      <Overlay
        visible={visible}
        onClose={() => {
          setVisible(!visible);
        }}
      >
        {
          setType()
        }
      </Overlay>
    </>
  );
}
