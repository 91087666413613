import * as React from 'react';

import {
  Colors,
  Breakpoint,

  useDimensions,
  getScrollHeight,
  getScrollDirection,
} from '@bluecurrent/web-component-lib';

import NavItem from './NavItem';

export default function PageNavigation(props) {
  const size = useDimensions();
  const scrollHeight = getScrollHeight();
  const scrollDirection = getScrollDirection();

  return (
    size.width >= Breakpoint.lg && (
      <div
        style={{
          height: 40,
          width: '100%',
          backgroundColor: Colors.LIGHT_WHITE,
          position: 'fixed',
          zIndex: 200,
          top: scrollDirection === 'down' && scrollHeight > 45
            ? 80
            : 125,
          transition: 'top .2s ease-in',
          borderBottom: `solid 2px ${Colors.MEDIUM_WHITE}`,
          display: 'flex',
          flexDirection: 'row',
          aignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {
          props.items.map((event) => (
            <NavItem
              /* eslint-disable-next-line no-underscore-dangle */
              key={event._key}
              data={event}
            />
          ))
        }
      </div>
    )
  );
}
