import * as React from 'react';

import {
  Breakpoint,
} from '@bluecurrent/web-component-lib';

export default function itemResponsiveHandler(size) {

  const responsive = () => {
    if (size.width > Breakpoint.md && size.width <= Breakpoint.xl) return 4;
    if (size.width > Breakpoint.sm && size.width <= Breakpoint.md) return 3;
    if (size.width > Breakpoint.fd && size.width <= Breakpoint.sm) return 2;
    if (size.width <= Breakpoint.fd) return 1;
    return 5;
  }

  return responsive();
}
